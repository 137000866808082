//@import "myriad";
//@import "ITC Slimbach Std";
//@import "bootstrap/bootstrap";

@font-face {
  font-family: "Slimbach";
  src: url("../assets/fonts/slim");
}

@font-face {
  font-family: "Myriad";
  src: url("../assets/fonts/myriad");
}

html, body {
  height: 100%;
  overflow-x: hidden;
  margin-left: -2px;
  margin-right: -2px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin-right: -2px;
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

main {
  flex: 1;
}

.card-home-header {
	background-color: #9B8E7E;
	opacity: 0.75;
	color: #fff;
}

.card-home-text {
  font-family: 'Myriad';
  font-weight: BOLD;
  font-size: 2.7rem;
  letter-spacing: 2px;
  white-space: nowrap;
  opacity: 1;
  color: #fff;
}

.card-home-subtext {
  letter-spacing: 1px;
  font-size: 1.2em;
  font-family: 'Myriad';
}

.box {
  min-width: 320px;
}

.white {
	color: #fff;
}

.hr-home-header {
	border-color: #fff !important;
	border-top: 2px solid white !important;
}

.hr-conn {
  border-color: #B1A6A0 !important;
  border-top: 1px solid #B1A6A0 !important;
}

.conn-text {
  color: #B1A6A0;
}

.small {
  font-size: 0.9rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.default-cover {
	background: url("/assets/images/cover-image.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 94vh;
  width: 100%;
}

.default-cover-2 {
  background: url("/assets/images/cover-image2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 94vh;
  width: 100%;
}

.sign-up-nav {
	background: #CBBC9F;
  max-height: 7vh;
}

.space {
	letter-spacing: 3px;
}

.mail {
	max-height: 10px;
  margin-bottom: 2px;
}

.mail-text {
	text-decoration: none;
	color: white;
}

.mail-text:hover {
	text-decoration: none;
	color: white;
}

.header-container {
	height: 74vh;
}

.page-footer {
    background-color: #7B6A58;
    &-copy {
        font-size: 0.9rem;
        color: #f8f9fa7a;
    }
    &-text {
        font-weight: 400;
    }
}

.footer-link {
  color: #fff;
  font-weight: lighter;
  font-size: 0.7rem !important;
}

.footer-link-bold {
  color: #fff;
  font-weight: bold;
  font-size: 0.7rem;
}

.footer-copy {
  color: #fff;
  font-weight: lighter;
  font-size: 0.7rem; 
}

.footer-copy-bold {
  color: #fff;
  font-size: 0.8rem; 
}

.nav-brand-logo {
  width: 100%;
  max-width: 250px;
}

.navbar-light .navbar-nav .nav-link {
  color: #151515;
  font-size: 0.9rem;
}

.signup-wine-lover {
  color: #fff;
  letter-spacing: 2px;
  font-size: 55%;
}

.navbar-toggler {
  max-height: 50px;
}

.scoll-arrow {
  margin-top: -20vh;
}

.scroll-text {
  font-weight: lighter;
  opacity: 0.8;
}

/* footer social icons */
.social-network {
  .facebook {
      background-color:#634D40;
  }
  .email {
      background-color:#634D40;
  }
  .instagram {
      background-color:#634D40;
  }
  .linkedin {
      background-color:#634D40;
  }
  li:not(:last-child) {
      margin-right: 12px;
  }
  &.social-circle li a {
      display: inline-flex;
      position: relative;
      margin: 0 auto 0 auto;
      border-radius: 50%;
      text-align: center;
      width: 32px;
      height: 32px;
      color: #fff;
      padding: .75rem;
      svg {
          color: #fff;
          transition: all 0.8s;
      }
      &:hover {
          text-decoration: none;
          svg {
              transform: rotate(360deg);
              transition: all 0.2s;
          }
      }
  }
}

svg, img {
    max-width: 100%;
}

.space-between {
  justify-content: space-between;
}

/*.navbar-collapse {
  background-color: #CBBC9F;
}*/

.paint-collapse {
  background-color: #CBBC9F;
}

.container-fluid {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;

}

.card {
  font-weight: lighter;
  border: 0px;
}

.card-body {
  padding-left: 0px;
}

.card-img-top {
  display: block;
  max-width: 100%;
  max-height: 300px;
  width: auto;
  height: auto;
}

.img-conn {
  max-width: 100px;
}

button:focus {
  outline: 0px;
}

.paint {
  background-color: #CBBC9F;
}

.card-link {
  color: #151515;
}

.card-link:hover {
  color: #7a6a57;
}

.subtitle {
  font-size: 1.1rem;
}

.subtitle-small {
 font-size: 1rem; 
}

.subtitle-bar {
  font-size: 3rem;
  font-weight: lighter;
  letter-spacing: 4px;
  color: #B1A6A0;
}

@media (min-width: 480px) {
  .signup-wine-lover {
    color: #fff;
    letter-spacing: 2px;
    font-size: 70%;
    white-space: nowrap;
  }

  .mail {
    max-height: 10px;
    margin-bottom: 3px;
  }
}

@media (min-width: 768px) {
  .signup-wine-lover {
    color: #fff;
    letter-spacing: 2px;
    font-size: 70%;
    white-space: nowrap;
  } 

  .mail {
    max-height: 10px;
    margin-bottom: 3px;
  }
}

@media (min-width: 768px) and (min-height: 900px) {
  .signup-wine-lover {
    color: #fff;
    letter-spacing: 2px;
    font-size: 70%;
    white-space: nowrap;
  }

  .mail {
    max-height: 10px;
    margin-bottom: 3px;
  }
}

.primary {
  color: #7B6A58;
}

.link {
}

.social-card {
  background-color: #DDD3C0;
}

.where {
  font-size: 1.4rem;
}

.h-item-card {
  background-color: #CBBC9F;
  color: white;
}

.banner {
  color: white; 
}

.banner-top {
  color: white;
  background-color: #CBBC9F;
}

.card2 {
  max-height: 200px;
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  border: none;
  border-radius: .28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top:20px;
  color: white;
}

.card-block {
  font-size: 1em;
  position: relative;
  margin: 0;
  padding: 1em;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, .1);
  box-shadow: none;
}

.h-card {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 165px;
  line-height: 1.5rem;
}

.no-link > a {
  text-decoration: none;
  color: #fff;
}

.max {

}

.social-text {
  font-size: 80%;
}

.social-name {
  font-size: 0.8rem;
}

.max-lines {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.2em;
  line-height: 1.2em;
}

.event-card {
  
}

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0;
}

.btn-sm {
  background-color: #404040;
  color: white;
  border-radius: 0;
  box-shadow: 0;
  border: 0;
}

.btn-sm:focus {
  background-color: #1f1f1f;
  color: white;
  border-radius: 0;
  box-shadow: 0;
  border: 0;
}

.btn-sm:hover {
  background-color: #1f1f1f;
  color: white;
  border-radius: 0;
  box-shadow: 0;
  border: 0;
}

a:focus {
  outline: 0;
  outline-color: none;
  outline-style: none;
  outline-width: 0px;
  color: white;
}

a:hover {
  color: #CBBC9F;
  text-decoration: none;
}

a {
  color: #634D40;
}

.light_a:hover {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.light_a {
  color: white;
}

.light_aa:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.light_aa {
  color: white;
}

.dark_aa:hover {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.dark_aa {
  color: black;
}

.dropdown-menu.show {
  background-color: white;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.dropdown-item {
  background-color: #CBBC9F;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0;
  color: white;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: white;
  background-color: #634D40;
}

.dropdown-menu {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.community {
  text-align: center;
}

.card-community {
  min-height: 100px;
}

.bottom-community {
  background-color: #634D40;
}

.secondary {
  color: #CBBC9F;
}

.back {
  height: 40px;
  margin-left: -5px;
}

.modal-content {
  background: #7B6A58;
}

.modal-body {
  background: #7B6A58;
}

.modal-header {
  border: 0px;
}

.holder {
  background-color: #CBBC9F;
}

.button-download {
  text-decoration: none;
  background-color: #634D40 !important;
  color: white;
  padding: 0.5rem;
  font-size: 0.8rem;
}

.small-text {
  font-size: 0.8rem; 
}

.download-title {
  font-size: 1.2rem;
}

.close {
  color: white;
}

.close:hover {
  color: white;
}

.legis_title {
  font-weight: bold;
  color: #634D40;

}
.legis_subtitle {
  color: #CBBC9F;
}
.legis_text {

}

.legis_hr {
  width: 10%;
  margin-left: 0;
}

.card-title {
  font-size: 1.1rem !important;
}

.card-text {
  font-size: 0.5rem !important; 
}

.IN-6d5494f8-69f0-4a9d-8c6b-3e3c3eb1b33b-1G9ISYhSF8XoOmdcl0yKDu {
  width: 75px !important;
}

.fb-share-button {
  width: 75px !important;
}

/*.a2a_kit > button {
  height: 200px;
  width: 200px;
}*/